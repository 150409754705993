import React, { Component } from 'react';
import { navigate, graphql } from "gatsby";

class Redirect extends Component {

    redirectMapping = (data) => {
        const {fitur, mode, weapon} = this.props.data;
        const mapper = {
            "feature" : `/guide/feature/${fitur.edges[0].node.slug}`,
            "mode" : `/guide/mode/${mode.edges[0].node.slug}`,
            "weapons" : `/guide/weapons/${weapon.edges[0].node.slug}`,
        }

        return mapper[data];
    }

    componentDidMount() {
        const pages = this.props.pageContext.pages;
        if (pages) {
            const redirect = this.redirectMapping(pages);
            navigate(redirect)
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default Redirect;

export const query = graphql`
{
    fitur: allDirectusFeature {
      edges {
        node {
          slug
        }
      }
    }
    mode: allDirectusMode {
      edges {
        node {
          slug
        }
      }
    }
    weapon: allDirectusWeapon {
      edges {
        node {
          slug
        }
      }
    }
  }
`